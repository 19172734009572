import EmailCheck from "./EmailCheck";
import "./AuthMain.css";

export default function AuthMain() {
    
    return (
            <div className="Memberbg">
                <EmailCheck />

            </div>
    );
}
