import UNFORGIVEN from '../img/JAPAN 2nd Single [UNFORGIVEN] Solo Jacket.png'

import './BodyImg.css';

export default function BodyImg() {
    return (
        <>
            <div className='barmenu'>
                <img src={UNFORGIVEN} className='bodyimg' />
            </div>
        </>
    )
}